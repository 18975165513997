import {
  Button,
  CloseButton,
  Group,
  Modal,
  Progress,
  ScrollArea,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import { HiCloudUpload } from "react-icons/hi";
import { HiPhoto } from "react-icons/hi2";
import useGlobalState, { globalFolder, globalProfile } from "../../globalState";
import CreateFolderDto from "../../model/CreateFolderDto";
import FileEntity from "../../model/File";
import FileMetadata from "../../model/FileMetadata";
import { FileMetadataUploadResponseDto } from "../../model/FileMetadataUploadResponseDto";
import useApi from "../../services/useApi";
import { getContentType } from "./ContentTypeUtils";
import "./FileDropzone.css";
import { formatBytes } from "../../utils/Utils";
import Folder from "../../model/Folder";
import User from "../../model/User";
import { useAppSelector } from "../../app/hooks";
import { selectCurrentFolder } from "../../state/explorer/explorerSlice";

interface PresignedUrl {
  fields: any;
  url: string;
}
interface UploadRequestItem {
  fileId: string;
  presignedUrl: PresignedUrl;
}
interface UploadRequestDto {
  content: UploadRequestItem[];
}

interface FolderParsed {
  uuid: string;
  name: string;
  parentFolderId: string;
  owner: string;
  path: string;
}

interface FileParsed {
  fileWithPath: FileWithPath;
  uuid: string;
  name: string;
  folderId: string;
}

const FILES_LIMIT_PER_UPLOAD = 400;

export default function FileDropzone(props: { onFilesUploaded; openRef }) {
  const [isModalOpened, { open, close }] = useDisclosure(false);
  const [dropzoneActive, setDropzoneActive] = useState(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState(false);
  const [dropzoneFoldersToUpload, setDropzoneFoldersToUpload] = useState<
    FolderParsed[]
  >([]);
  const [dropzoneFilesToUpload, setDropzoneFilesToUpload] = useState<
    FileParsed[]
  >([]);
  const { uploadFiles, loading } = useApi();
  const folder = useAppSelector(selectCurrentFolder);

  const [profile, setProfile] = useGlobalState<User>(globalProfile);
  const [uploadProgress, setUploadProgress] = useState(null);
  const [uploadProgressLabel, setUploadProgressLabel] = useState("");

  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const parseDroppedFiles = (files: FileWithPath[]) => {
    //console.log(files);
    const parsedFiles: FileParsed[] = [];
    const newFolderByPathMap = {};

    files.forEach((file) => {
      const isFileInFolder = file.path.startsWith("/");

      if (isFileInFolder) {
        const folderPath = file.path.split("/").splice(1);
        const fileName = folderPath.pop();

        // Create non existing parent folders
        folderPath.forEach((folderName, index) => {
          const folderPathJoined = folderPath.slice(0, index + 1).join("/");
          const folderId = crypto.randomUUID();
          const parentFolder =
            index === 0
              ? folder
              : newFolderByPathMap[folderPath.slice(0, index).join("/")];
          const parentFolderId = parentFolder.id ?? parentFolder.uuid; // id is used for root folder, uuid for the rest

          if (newFolderByPathMap[folderPathJoined] === undefined) {
            newFolderByPathMap[folderPathJoined] = {
              uuid: folderId,
              name: folderName,
              parentFolderId,
              owner: folder.owner,
              path: `${parentFolder.path};${folderId}:${folderName}`,
            } as FolderParsed;
          }
        });

        const folderPathJoined = folderPath.join("/");
        const folderName = folderPath[folderPath.length - 1];
        const parentFolderPath = folderPath
          .slice(0, folderPath.length - 1)
          .join("/");
        const parentFolder: FolderParsed = newFolderByPathMap[parentFolderPath];

        let folderId: string;

        // Check if folder exists
        if (newFolderByPathMap[folderPathJoined] !== undefined) {
          const existingFolder: FolderParsed =
            newFolderByPathMap[folderPathJoined];
          folderId = existingFolder.uuid;
        } else {
          // Create new folder
          const newFolderId = crypto.randomUUID();
          const folderParsed: FolderParsed = {
            uuid: newFolderId,
            name: folderName,
            parentFolderId: parentFolder.uuid,
            owner: folder.owner,
            path: `${parentFolder.path};${newFolderId}:${folderName}`,
          };

          newFolderByPathMap[folderPathJoined] = folderParsed;
          folderId = newFolderId;
        }

        parsedFiles.push({
          fileWithPath: file,
          folderId,
          name: fileName,
          uuid: crypto.randomUUID(),
        } as FileParsed);
      } else {
        parsedFiles.push({
          fileWithPath: file,
          folderId: folder.id,
          name: file.path,
          uuid: crypto.randomUUID(),
        } as FileParsed);
      }
    });

    const foldersToUpload: FolderParsed[] = Object.values(newFolderByPathMap);
    const filesToUpload: FileParsed[] = parsedFiles;

    console.log(foldersToUpload, filesToUpload);

    return { foldersToUpload, filesToUpload };
  };

  const onFilesDrop = (files: FileWithPath[]) => {
    const { foldersToUpload, filesToUpload } = parseDroppedFiles(files);

    // Open upload modal
    open();
    setDropzoneActive(false);

    setDropzoneFilesToUpload(filesToUpload);
    setDropzoneFoldersToUpload(foldersToUpload);
  };

  const buildS3UploadPromise = (
    file: FileEntity,
    rawFile: FileWithPath,
    presignedPostUrl: PresignedUrl,
    progressIncrement: number
  ): (() => Promise<any>) => {
    const formData = new FormData();

    // Add all fields to the form data
    Object.entries(presignedPostUrl.fields).forEach(([key, value]: any) => {
      formData.append(key, value);
      console.debug("append to formData: ", key, value);
    });

    // Add the file to the form data
    formData.append("file", rawFile);

    // Calculate the Content-Type
    formData.append("Content-Type", getContentType(file.name));
    console.debug(
      `Content-Type for ${file.name} is ${getContentType(file.name)}`
    );

    // Make a POST request to the presigned POST URL
    return () =>
      fetch(presignedPostUrl.url, {
        method: "POST",
        body: formData,
      }).then((response) => {
        setUploadProgress(
          (currentProgress) => currentProgress + progressIncrement
        );
      });
  };

  const updateUploadProgressToDuring = (
    proressFrom: number,
    progressTo: number,
    durationInMs: number
  ): any => {
    const increment = (progressTo - proressFrom) / 100;
    let currentProgress = proressFrom;

    const interval = setInterval(() => {
      currentProgress += increment;
      setUploadProgress(currentProgress);

      if (currentProgress >= progressTo) {
        clearInterval(interval);
      }
    }, durationInMs / 100);

    return interval;
  };

  const promisePool = async (limit, array, iteratorFn) => {
    const ret = []; // Array to store results
    const executing = []; // Array to store active promises

    for await (const item of array) {
      const p = Promise.resolve().then(() => iteratorFn(item));
      ret.push(p);

      if (limit <= array.length) {
        const e = p.then(() => executing.splice(executing.indexOf(e), 1));
        executing.push(e);

        if (executing.length >= limit) {
          await Promise.race(executing);
        }
      }
    }

    return Promise.allSettled(ret);
  };

  const onUploadSubmit = async () => {
    setIsUploadInProgress(true);

    setUploadProgressLabel("Preparing files");
    const interval = updateUploadProgressToDuring(0, 40, 10000);

    // Metadata upload
    const filesMapped: FileMetadata[] = dropzoneFilesToUpload.map(
      (dropzoneFile, index): FileMetadata => {
        const parentFolder =
          dropzoneFoldersToUpload.find(
            (f: FolderParsed) => f.uuid === dropzoneFile.folderId
          ) ?? folder;
        return {
          id: dropzoneFile.uuid,
          uuid: dropzoneFile.uuid,
          name: dropzoneFile.name,
          folderId: dropzoneFile.folderId,
          dateCreated: new Date(),
          sizeInBytes: dropzoneFile.fileWithPath.size,
          owner: parentFolder.owner,
        };
      }
    );
    const foldersMapped: CreateFolderDto[] = dropzoneFoldersToUpload.map(
      (folderParsed: FolderParsed): CreateFolderDto => {
        return {
          id: folderParsed.uuid,
          parentFolderId: folderParsed.parentFolderId,
          name: folderParsed.name,
          owner: folderParsed.owner,
          readUsers: ["aitorleria@gmail.com"], // TODO: FIX
          writeUsers: ["aitorleria@gmail.com"], // TODO: FIX
          dateCreated: new Date(),
          path: folderParsed.path,
        };
      }
    );

    // Split files into batches of size 400
    const filesMappedBatches: FileMetadata[][] = [];
    for (let i = 0; i < filesMapped.length; i += FILES_LIMIT_PER_UPLOAD) {
      const chunk = filesMapped.slice(i, i + FILES_LIMIT_PER_UPLOAD);
      filesMappedBatches.push(chunk);
    }

    console.log(
      "Files and folders to upload",
      filesMappedBatches,
      foldersMapped
    );

    const allUploadPromises = [];
    const allNewFiles = [];

    // Loop filesMappedBatches with forin and upload each batch
    let batchIndex = 0;
    for await (const filesMappedBatch of filesMappedBatches) {
      const metadataUploadResponse: FileMetadataUploadResponseDto =
        await uploadFiles(
          filesMappedBatch,
          batchIndex++ === 0 ? foldersMapped : []
        );

      console.log("metadataUploadResponse", metadataUploadResponse);

      const progressIncrement = 60 / filesMapped.length;

      const uploadPromises = metadataUploadResponse.files.map((file, index) =>
        buildS3UploadPromise(
          file,
          dropzoneFilesToUpload.find((f) => f.uuid === file.uuid).fileWithPath,
          metadataUploadResponse.presignedUrls.find(
            (pu) => pu.fileId === file.uuid
          ).presignedUrl,
          progressIncrement
        )
      );

      await Promise.allSettled(uploadPromises.map((promise) => promise()));

      allUploadPromises.push(...uploadPromises);
      allNewFiles.push(...metadataUploadResponse.files);
    }

    /*filesMappedBatches.forEach(async (filesMappedBatch, index) => {
      const metadataUploadResponse: FileMetadataUploadResponseDto =
        await uploadFiles(filesMappedBatch, index === 0 ? foldersMapped : []);

        console.log("metadataUploadResponse", metadataUploadResponse);

        const progressIncrement = 60 / filesMapped.length;

        const uploadPromises = metadataUploadResponse.files.map((file, index) =>
          buildS3UploadPromise(
            file,
            dropzoneFilesToUpload.find((f) => f.uuid === file.uuid).fileWithPath,
            metadataUploadResponse.presignedUrls.find(
              (pu) => pu.fileId === file.uuid
            ).presignedUrl,
            progressIncrement
          )
        );

        allUploadPromises.push(...uploadPromises);
        allNewFiles.push(...metadataUploadResponse.files);
    });*/

    // TODO: delete
    // Update progress bar
    clearInterval(interval);
    setUploadProgress(40);
    setUploadProgressLabel("Uploading files");

    cleanUp();
    close();
    props.onFilesUploaded(allNewFiles, foldersMapped);
  };

  function cleanUp() {
    setDropzoneFilesToUpload([]);
    setDropzoneFoldersToUpload([]);
    setUploadProgress(null);
    setUploadProgressLabel("");
    setIsUploadInProgress(false);
  }

  return (
    <Dropzone.FullScreen
      openRef={props.openRef}
      onDrop={onFilesDrop}
      disabled={false}
    >
      <Group
        justify="center"
        gap="xl"
        mih={220}
        style={{ pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <HiCloudUpload />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <CloseButton />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <HiPhoto />
        </Dropzone.Idle>

        <div>
          <Text>Drag images here</Text>
          <Text>Each file should not exceed 5mb</Text>
        </div>
      </Group>

      <Modal
        opened={isModalOpened}
        onClose={close}
        title="Upload files"
        closeOnClickOutside={!isUploadInProgress}
        closeOnEscape={!isUploadInProgress}
        withCloseButton={!isUploadInProgress}
        fullScreen={isMobile}
      >
        <ScrollArea
          scrollbarSize={14}
          scrollHideDelay={1500}
          type="hover"
          className="w-full max-h-96 overflow-auto flex flex-col"
        >
          {dropzoneFilesToUpload.map((file, index) => {
            return (
              <div
                className="w-full
              flex flex-row justify-between items-baseline mt-2"
              >
                <Text size="xs" className="text-ellipsis overflow-hidden">
                  {file.fileWithPath.name}
                </Text>
                <Text size="xs" className="text-nowrap text-right w-20">
                  {formatBytes(file.fileWithPath.size)}
                </Text>
              </div>
            );
          })}
        </ScrollArea>

        <div className="w-full flex flex-row justify-between items-baseline mt-2">
          <Text size="md" className="text-ellipsis overflow-hidden">
            Total size
          </Text>
          <Text size="md" className="">
            {formatBytes(
              dropzoneFilesToUpload.reduce(
                (acc, file) => acc + file.fileWithPath.size,
                0
              )
            )}
          </Text>
        </div>

        <div className="flex justify-end mt-4">
          <Button
            className="flex"
            onClick={onUploadSubmit}
            variant="filled"
            loading={false}
            disabled={isUploadInProgress}
          >
            Upload
          </Button>
        </div>

        <div className="mt-4 text-center flex flex-col gap-2">
          {uploadProgress != null ? (
            <Progress
              radius="md"
              size="sm"
              value={uploadProgress >= 100 ? 100 : uploadProgress}
              animated={true}
            />
          ) : (
            ""
          )}
          {uploadProgressLabel}
        </div>
      </Modal>
    </Dropzone.FullScreen>
  );
}
