import {
  Button,
  Container,
  Divider,
  Group,
  LoadingOverlay,
  NumberInput,
  Select,
  Switch,
  Text,
  useComputedColorScheme,
  useMantineColorScheme,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useState } from "react";
import { HiCog, HiRefresh, HiTemplate } from "react-icons/hi";
import { exampleGalleryFiles } from "../../examples/files";
import Gallery from "../gallery/Gallery";
import useGlobalState, { globalSettings } from "../../globalState";
import { SETTINGS_DEFAULTS, Settings as SettingsType } from "../../settings";

export default function Settings() {
  const { setColorScheme, clearColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const [settings, setSettings] = useGlobalState<SettingsType>(globalSettings);

  const computedColorScheme = useComputedColorScheme("dark");

  const [shouldRerender, setShouldRerender] = useState(false);

  const settingsSections = [
    {
      title: "General",
      icon: <HiCog />,
      description: null,
      items: [
        {
          title: "Dark mode",
          description: "Toggle dark mode for all app",
          getDefaultValue: () => computedColorScheme === "dark",
          action: () => {
            setColorScheme(computedColorScheme === "dark" ? "light" : "dark");
          },
          type: "switch",
        },
        {
          title: "Animations",
          description: "Toggle animations for all app",
          settingsKey: "animations_enabled",
          type: "switch",
        },
      ],
    },
    {
      title: "Gallery",
      icon: <HiTemplate />,
      description: "Image visualization and gallery settings",
      items: [
        {
          title: "Layout",
          description: "Set the layout type",
          settingsKey: "photoAlbum_layout",
          type: "select",
          options: [
            { value: "rows", label: "Rows" },
            { value: "columns", label: "Columns" },
            { value: "masonry", label: "Masonry" },
          ],
        },
        {
          title: "Spacing",
          description: "Set the spacing between images",
          settingsKey: "photoAlbum_spacing",
          type: "number",
        },
        {
          title: "Row height",
          description: "Set the target row height",
          settingsKey: "photoAlbum_targetRowHeight",
          type: "number",
        },
        {
          title: "Slideshow delay",
          description: "The delay between slides in the slideshow",
          settingsKey: "slideshow_delay",
          type: "numberWithUnit",
          mapValueToDisplay: (value) => value / 1000,
          mapValueToStore: (value) => value * 1000,
          unit: "seconds",
          min: 0,
          max: 1000000,
        },
      ],
      renderExample: () => (
        <div className="flex flex-col pt-4">
          <Gallery
            isMiniGallery={true}
            folders={[]}
            files={exampleGalleryFiles as any}
            loadMoreFn={() => {}}
            onFolderClickFn={() => {}}
            onActionClick={() => {}}
            isSelectionEnabled={false}
          ></Gallery>
        </div>
      ),
    },
  ];

  const onSettingChange = (item, value) => {
    if (!!item.action) {
      item.action();
    }

    // Save setting to localStorage
    if (!item?.settingsKey) return;
    const storedSettings = localStorage.getItem("settings");

    if (
      storedSettings != null &&
      storedSettings !== "" &&
      storedSettings !== undefined
    ) {
      const settingsObj = JSON.parse(storedSettings);

      if (item?.mapValueToStore) {
        value = item.mapValueToStore(value);
      }

      settingsObj[item.settingsKey] = value;

      localStorage.setItem("settings", JSON.stringify(settingsObj));
      setSettings({
        ...settings,
        [item.settingsKey]: value,
      });
    } else {
      localStorage.setItem(
        "settings",
        JSON.stringify({ [item.settingsKey]: value })
      );
      setSettings({ ...settings, [item.settingsKey]: value });
    }
  };

  const getDefaultValue = (item) => {
    if (item?.getDefaultValue) {
      return item.getDefaultValue();
    }
    if (item?.settingsKey && settings[item.settingsKey]) {
      if (item?.mapValueToDisplay) {
        return item.mapValueToDisplay(settings[item.settingsKey]);
      }
      return settings[item.settingsKey];
    }
    return null;
  };

  const renderSettingItem = (item) => {
    if (item.type === "switch") {
      return (
        <div className="flex flex-row gap-2">
          <Switch
            onLabel="ON"
            offLabel="OFF"
            className="switch"
            size="md"
            defaultChecked={getDefaultValue(item)}
            disabled={item["disabled"]}
            onChange={(value) => onSettingChange(item, value.currentTarget.checked)}
          />
        </div>
      );
    }

    if (item.type === "number") {
      return (
        <div className="flex flex-row gap-2">
          <NumberInput
            className="numberInput"
            size="md"
            defaultValue={getDefaultValue(item)}
            disabled={item["disabled"]}
            onChange={(value) => onSettingChange(item, value)}
            stepHoldDelay={500}
            stepHoldInterval={100}
          />
        </div>
      );
    }

    if (item.type === "numberWithUnit") {
      return (
        <div className="flex flex-row gap-2">
          <NumberInput
            className="numberInput"
            size="md"
            defaultValue={getDefaultValue(item)}
            suffix={` ${item.unit}`}
            disabled={item["disabled"]}
            onChange={(value) => onSettingChange(item, value)}
            min={item.min}
            max={item.max}
            clampBehavior="strict"
            stepHoldDelay={500}
          />
        </div>
      );
    }

    if (item.type === "select") {
      return (
        <div className="flex flex-row gap-2">
          <Select
            className="select"
            size="md"
            defaultValue={getDefaultValue(item)}
            disabled={item["disabled"]}
            onChange={(_value, option) => onSettingChange(item, _value)}
            data={item.options}
          ></Select>
        </div>
      );
    }

    return <></>;
  };

  const resetDefaultSettings = () => {
    setSettings(SETTINGS_DEFAULTS);
    localStorage.removeItem("settings");
    setShouldRerender(true);
  };

  const mapItem = (item) => (
    <Group
      justify="space-between"
      className="item"
      wrap="nowrap"
      gap="xl"
      mb="sm"
      key={item.title}
    >
      <div>
        <Text>{item.title}</Text>
        <Text size="xs" c="dimmed">
          {item.description}
        </Text>
      </div>
      {renderSettingItem(item)}
    </Group>
  );

  if (shouldRerender) {
    setTimeout(() => {
      setShouldRerender(false);
    }, 500);
    return (
      <div className="flex flex-col gap-2 h-48">
        <LoadingOverlay
          visible={true}
          zIndex={400}
          overlayProps={{
            blur: "xl",
            color: computedColorScheme === "dark" ? "black" : "black",
            opacity: computedColorScheme === "dark" ? "70%" : "30%",
          }}
          loaderProps={{ color: "blue", type: "bars", size: "xl" }}
        />
      </div>
    );
  }

  return (
    <Container
      className="w-full mx-auto"
      p={isMobile ? "md" : "xl"}
      maw={"40rem"}
    >
      <Text fz="xl" className="title" fw={500}>
        Settings
      </Text>

      {settingsSections.map((section) => (
        <div key={section.title} className="mt-8 card">
          <div className="flex items-center gap-2">
            {section.icon}
            <Text fz="lg" fw={500}>
              {section.title}
            </Text>
          </div>
          <Text fz="md" c="dimmed" mb={"md"}>
            {section?.description}
          </Text>

          <Divider mt={14} mb={18} />

          <div className="items">
            {section?.items.map((item) => mapItem(item))}
          </div>

          <div>{section?.renderExample && section.renderExample()}</div>
        </div>
      ))}

      <div className="flex justify-end mt-10">
        <Button onClick={resetDefaultSettings} leftSection={<HiRefresh />}>
          Reset default settings
        </Button>
      </div>
    </Container>
  );
}
